// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"ce3e8f33d6ffe5ce2490f3fda55cec518074d0bf"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import { SUPPORTED_ENVS } from "./src/config/constants";

Sentry.init({
  dsn: "https://756d724c7088076c8000f7bc55d1764a@o4508326426181632.ingest.de.sentry.io/4508492933562448",
  integrations: [
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  debug: false,
  environment: process.env.NEXT_PUBLIC_ENV_NAME,
  enabled: SUPPORTED_ENVS.includes(process.env.NEXT_PUBLIC_ENV_NAME || ''),
  ignoreErrors: [
    "Cannot read properties of null (reading 'portalId')",
    "null is not an object (evaluating 's.portalId')"
  ],
});
